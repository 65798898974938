
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .badges > * {
    margin-right: 8px;
  }
  .container {
    padding: 16px;
    display: flex;
    justify-content: center;
  }
  
  .input {
    width: 45px;
    height: 45px;
    padding: 0;
    font-size: 24px;
    text-align: center;
    margin-right: 12px;
    text-transform: uppercase;
    color: #494949;
    font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
      sans-serif;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    background: #fff;
    background-clip: padding-box;
  }
  
  .input:focus {
    appearance: none;
    outline: 0;
    box-shadow: 0 0 0 3px rgb(131 192 253 / 50%);
  }
  
 
  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d6d6d6;
    padding: 8px 16px;
  }